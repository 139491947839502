import React, { useEffect, useState } from 'react'
import Cookie from 'js-cookie'
import { get } from 'lodash'
import './style.css'
import queryString from 'query-string'
import { Auth, Hub } from 'aws-amplify'
import { AmplifyAuthenticator, AmplifySignIn, AmplifyToast } from '@aws-amplify/ui-react'
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components'
import Logo from 'svg/emi-logo.svg'
import { getAppUrl, urlHasValidDomain, getDomain, getSecureCookies } from 'utils'

import { I18n } from '@aws-amplify/core'

function LoginPage ({ location }) {
  const [callback, setCallback] = useState('')

  const [authState, setAuthState] = React.useState()
  const [user, setUser] = React.useState()

  const [errorMsg, setErrorMsg] = React.useState('')
  
  var callbackUrl = queryString.parse(location.search).callbackUrl

  if (callbackUrl && !urlHasValidDomain(callbackUrl)) {
    callbackUrl = undefined
  }
  if (callbackUrl && callback !== callbackUrl) {
    setCallback(callbackUrl)
    console.log({ callbackUrl })
  }

  useEffect(() => {
    return onAuthUIStateChange((newAuthState, authData) => {
      setAuthState(newAuthState)
      setUser(authData)
      setErrorMsg('')
      if (newAuthState === AuthState.SignedIn) {
        Auth.currentAuthenticatedUser().then(user => {
          const cookieOpts = { expires: 7, domain: getDomain(), sameSite: 'strict', secure: getSecureCookies() }
          Cookie.set('accessToken', get(user, 'signInUserSession.accessToken.jwtToken'), { ...cookieOpts, expires: 1 })
          Cookie.set('idToken', get(user, 'signInUserSession.idToken.jwtToken'), { ...cookieOpts, expires: 1 })
          Cookie.set('refreshToken', get(user, 'signInUserSession.refreshToken.token'), cookieOpts)
          Cookie.remove('loginPath', cookieOpts)

          window.location.replace(callback || getAppUrl())
        }).catch(() => console.log('Not signed in'))
      }
    })
  }, [])

  React.useEffect(() => {
    Hub.listen('auth', (res) => {
      let replaceError = false
      let errorMsg = res?.payload?.data?.message
        ? res.payload.data.message
        : null

      if (!errorMsg) return

      if (errorMsg.includes("'userName' failed to satisfy constraint")) {
        replaceError = true
        errorMsg = 'invalid_email'
      }

      if (errorMsg.includes("Value at 'username' failed to satisfy constraint")) {
        replaceError = true
        errorMsg = 'invalid_email'
      }

      if (errorMsg.includes("Value at 'password' failed to satisfy")) {
        replaceError = true
        errorMsg = 'invalid_password'
      }

      // Hide Amplify's default Toast since we're showing our own
      const target = document.getElementsByTagName('amplify-authenticator')[0]
      if (replaceError) {
        if (target?.shadowRoot?.children) {
          setTimeout(() => {
            target.shadowRoot.children[0].style.display = 'none'
          }, 100) // needed because node isn't injected straight away
        }
      }
      setErrorMsg(I18n.get(errorMsg))
    })
  }, [])

  return (
    <div className='wrapper'>
      <div className='content'>
        <img src={Logo} className='logo' alt='Emilabs' />
        <AmplifyAuthenticator>

          <AmplifySignIn
            slot='sign-in'
            hideSignUp
            handle
            headerText='SIGN_IN_HEADER_TEXT'
            submitButtonText='SIGN_IN_ACTION'
          />

        </AmplifyAuthenticator>
        {errorMsg && <AmplifyToast slot='amplify-toast' message={errorMsg} onClick={() => setErrorMsg('')} />}

      </div>

    </div>
  )
}

export default LoginPage
