import React, { useEffect, useState } from 'react'
import queryString from 'query-string'

const JWTLoginRedirect = ({ location }) => {
  const { token } = queryString.parse(location.search)
    
  window.location.replace(`${process.env.REACT_APP_JWT_VALIDATION_BASE_URL}${location.pathname}?token=${token}`)

  return (
    null
  )
}

export default JWTLoginRedirect
