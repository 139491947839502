import React from 'react'
import queryString from 'query-string'
import { AmplifyToast } from '@aws-amplify/ui-react'
import './style.css'

class SSOLogoutPage extends React.Component {
  constructor () {
    super()
    this.state = { msg: '' }
  }

  async componentDidMount () {
    const { msg } = queryString.parse(window.location.search)
    this.setState({
      msg
    })
  }

  render () {
    return (
      <div>
        <AmplifyToast
          slot='amplify-toast' message={this.state.msg}
        />
      </div>
    )
  }
}

export default SSOLogoutPage
