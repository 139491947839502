export const getDomain = () => {
  if (window.location.hostname === 'localhost' || window.location.hostname.startsWith('localhost:')) {
    return 'localhost'
  }

  if (window.location.hostname.endsWith('.emilabs.ai')) {
    return '.emilabs.ai'
  }

  if (window.location.hostname.endsWith('.emilabs.io')) {
    return '.emilabs.io'
  }

  return '.emilabs.ai'
}

export const urlHasValidDomain = (url) => {
  const u = new URL(url)

  return u.hostname === 'localhost' 
  || u.hostname.startsWith('localhost:')
  || u.hostname.endsWith('.emilabs.ai')
  || u.hostname.endsWith('.emilabs.io')
}

export const getAppUrl = () => {
  if (window.location.hostname.startsWith('localhost')) {
    return '//localhost:3000'
  }

  if (window.location.hostname === 'login.emilabs.ai') {
    return '//app.emilabs.ai'
  }

  if (window.location.hostname === 'login-staging.emilabs.io') {
    return '//cbo-frontend-staging.emilabs.io'
  }

  return '//app.emilabs.ai'
}

export const getSecureCookies = () => {
  return getDomain() === '.emilabs.ai'
}
