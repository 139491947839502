import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './App.css'
import LoginPage from 'pages/LoginPage'
import SamlLogin from 'pages/SamlLogin' // using Cognito App Client and Hosted UI
import SSOLoginRedirect from 'pages/SSOLoginRedirect' // using Cognito SDK and passport-saml
import SSOErrorPage from 'pages/SSOErrorPage' // render SSO login errors for user
import Logout from 'pages/Logout'
import SSOLogoutPage from 'pages/SSOLogoutPage' // render logout message for SSO
import JWTLoginRedirect from 'pages/JWTLoginRedirect'

/**
 * Routes:
 * /test -> emi idp (hosted UI SSO + amplify)
 * /iconn -> iconn idp server side SSO (passport)
 * /simplesaml -> emi idp server side SSO (passport)
 * /walmart -> walmart idp server side SSO (passport)
 */

function App () {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/signout' component={Logout} />
        <Route exact path='/logout' component={SSOLogoutPage} />
        <Route exact path='/error' component={SSOErrorPage} />
        <Route exact path='/test' component={SamlLogin} />
        <Route exact path='/oxxo/jwt' component={JWTLoginRedirect} />
        <Route exact path='/:subsidiary' component={SSOLoginRedirect} />
        <Route component={LoginPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
