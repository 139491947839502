import React from 'react'
import Cookie from 'js-cookie'
import { getDomain, getSecureCookies } from 'utils'
import queryString from 'query-string'

class SSOLoginRedirect extends React.Component {
  render () {
    let targetUrl

    if (window.location.host.endsWith('.emilabs.ai')) {
      targetUrl = 'https://sso.emilabs.ai/login'
    }

    if (window.location.host.endsWith('.emilabs.io')) {
      targetUrl = 'https://sso-staging.emilabs.io/login'
    }

    const cookieOpts = { expires: 7, domain: getDomain(), secure: getSecureCookies(), path: '/' }
    Cookie.set('loginPath', window.location.pathname, cookieOpts)

    const { redirectUri } = queryString.parse(window.location.search)
    
    window.location.replace(`${targetUrl}${window.location.pathname}?RelayState=${encodeURIComponent(JSON.stringify({ redirectUri }))}`)

    return (
      null
    )
  }
}

export default SSOLoginRedirect
