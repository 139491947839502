import React, { Component } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { withOAuth } from 'aws-amplify-react'
import Cookie from 'js-cookie'
import { getAppUrl, getDomain, getSecureCookies } from 'utils'
import { get } from 'lodash'
import './style.css'
import queryString from 'query-string'
import { AmplifyToast } from '@aws-amplify/ui-react'

class SamlLogin extends Component {
  constructor () {
    super()
    this.state = { redirectToHostedUI: false, authState: {}, user: {}, isSignInError: false }
  }

  async componentDidMount () {
    const { error, error_description } = queryString.parse(window.location.hash)
    if (error) {
      this.setState({
        isSignInError: true,
        errorMsg: error_description
          .replace('Error in SAML response processing: PreSignUp failed with error', ''),
        loginUrl: window.location.href
          .replace(window.location.search, '')
          .replace(window.location.hash, '')
      })
    } else {
      this.setState({ redirectToHostedUI: true })
    }
    Hub.listen('auth', this)
  }

  onHubCapsule (capsule) {
    const { channel, payload } = capsule
    if (channel === 'auth' && payload.event === 'signIn') {
      this.checkUser()
    }
  }

  checkUser () {
    const appUrl = getAppUrl()

    Auth.currentAuthenticatedUser().then(user => {
      this.setState({ redirectToHostedUI: false, newAuthState: user, user })

      const cookieOpts = { expires: 7, domain: getDomain(), sameSite: 'strict', secure: getSecureCookies() }
      Cookie.set('accessToken', get(user, 'signInUserSession.accessToken.jwtToken'), { ...cookieOpts, expires: 1 })
      Cookie.set('idToken', get(user, 'signInUserSession.idToken.jwtToken'), { ...cookieOpts, expires: 1 })
      Cookie.set('refreshToken', 'fake-refresh-token', { domain: getDomain() })
      Cookie.set('loginPath', window.location.pathname, cookieOpts)

      window.location.replace(appUrl)
    }).catch(() => console.log('Not signed in'))
  }

  render () {
    let placeholder
    let response = (
      <div className='App'>
        <div class='spinner-container'>
          <div class='spinner'>
            <div class='double-bounce1' />
            <div class='double-bounce2' />
            {placeholder}
          </div>
        </div>
      </div>
    )

    if (this.state.redirectToHostedUI) {
      placeholder = (this.props.OAuthSignIn())
    }

    if (this.state.isSignInError) {
      response = (
        <div>
          <AmplifyToast
            slot='amplify-toast' message={this.state.errorMsg} onClick={() => window.location.replace(this.state.loginUrl)}
          />
        </div>
      )
    }

    return (
      response
    )
  }
}

export default withOAuth(SamlLogin)
