import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import Cookie from 'js-cookie'
import { getAppUrl, getDomain, urlHasValidDomain } from '../../utils'
import queryString from 'query-string'

async function Logout ({ location }) {
  const [callback, setCallback] = useState('')
  const { callbackUrl } = queryString.parse(location.search)

  if (callbackUrl && !urlHasValidDomain(callbackUrl)) {
    callbackUrl = undefined
  }

  if (callbackUrl && callback !== callbackUrl) {
    setCallback(callbackUrl)
    console.log({ callbackUrl })
  }

  const result = await Auth.signOut()
  console.log(`signOut result: ${result}`)

  Cookie.remove('user', { domain: getDomain() })
  Cookie.remove('idToken', { domain: getDomain() })
  Cookie.remove('accessToken', { domain: getDomain() })
  Cookie.remove('refreshToken', { domain: getDomain() })

  // check if custom login path for SSO login is set
  if (Cookie.get('loginPath')) {
    // TODO: regionalize logout msg
    window.location.replace(`${window.location.origin}/logout?msg=Ha%20salido%20de%20la%20sesi%C3%B3n.%20Vuelve%20al%20login%20corporativo%20para%20ingresar%20nuevamente.`)
  } else {
    window.location.replace(`/?callbackUrl=${callback || getAppUrl()}`)
  }

  return (
    <div>Signing out...</div>
  )
}

export default Logout
