import React from 'react'
import queryString from 'query-string'
import { AmplifyToast } from '@aws-amplify/ui-react'

class SSOErrorPage extends React.Component {
  constructor () {
    super()
    this.state = { errorMsg: '', loginUrl: '' }
  }

  async componentDidMount () {
    const { message, callbackUrl } = queryString.parse(window.location.search)
    console.log(message, callbackUrl)
    this.setState({
      errorMsg: message,
      callbackUrl
    })
  }

  render () {
    return (
      <div>
        <AmplifyToast
          slot='amplify-toast' message={this.state.errorMsg} onClick={() => window.location.replace(this.state.callbackUrl)}
        />
      </div>
    )
  }
}

export default SSOErrorPage
