import Amplify from 'aws-amplify'
import { getDomain, getSecureCookies } from 'utils'
import { initI18n } from '../translations'

initI18n()

const authConfig = {
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_APP_ID,
  mandatorySignIn: false,
  storage: localStorage,
  cookieStorage: {
    domain: getDomain(),
    path: '/',
    expires: 365,
    secure: getSecureCookies()
  }
}

Amplify.configure({ Auth: authConfig })

// cookies: https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource

// I18n.setLanguage('es')
